import {
	AddCartRequest,
	AddToCartRequestType,
	AddToCartResponseType,
	AddWishlistRequestType,
	ApplyCouponRequestType,
	ApplyCouponResponseType,
	ApproveDeclineEndorsementRequestType,
	ApproveDeclineEndorsementResponseType,
	BlockAllUsersType,
	bookBulkInteractionRequestType,
	ChangeDefaultWishlistRequestType,
	CheckoutResponseType,
	CollegeBulkActionRequestType,
	consultationBulkInteractionRequestType,
	CreateBlogRequestType,
	CreateBlogResponseType,
	CreateBookRequestType,
	CreateBookResponseType,
	CreateEndorsementRequestType,
	CreateEventRequestType,
	CreateEventResponseType,
	CreateEventSpeakerRequestType,
	CreateEventSpeakerResponseType,
	CreateJobRequestType,
	CreateJobResponseType,
	createRoomRequestType,
	createRoomResponseType,
	CreateServiceRequestType,
	CreateServiceResponseType,
	DeleteAdRequestType,
	DeleteEndorsmentByIdRequestType,
	deleteLanguageType,
	DeleteNewsRequestType,
	DeleteNoteRequestType,
	DeleteNoteResponseType,
	deletePersonalProfileLanguageRequestType,
	deletePersonalProfileSocialMediaRequestType,
	deletePersonalProfileSocialMediaResponseType,
	deleteShippingAddressType,
	EndorsmentByIdRequestType,
	FolderCreateRequestType,
	FolderDeleteRequestType,
	FolderUpdateRequestType,
	FreelancingServiceResource,
	getBookInterationRequest,
	getbulkJobPostingRequestType,
	getbulkJobPostingResponseType,
	getJobCandidateRequestType,
	getJobCandidatesStatusRequestType,
	getJobCandidatesStatusResponseType,
	getJobPostingInteractionRequestType,
	getSavedUsersRequestType,
	getServicePostingInteractionRequestType,
	LoginByEmailResponseType,
	LoginByPhoneRequestType,
	LoginByPhoneResponseType,
	LoginRelatedAccountResponseType,
	LoginRelatedAccountResquestType,
	LogoutResponseType,
	moveBulkWishlistRequestType,
	NewNoteRequestType,
	NewNoteResponseType,
	RemoveBulkWishlistRequestType,
	removeCartRequest,
	RemoveFromCartRequestType,
	RemoveFromCartResponseType,
	RemoveWishlistRequestType,
	sendEmailVerificationRequestType,
	sendEmailVerificationResponseType,
	sendPhoneVerificationRequestType,
	sendPhoneVerificationResponseType,
	SponsorshipBulkActionRequestType,
	// sendEmailVerificationRequestType,
	// sendEmailVerificationResponseType,
	storeNewEntityProfileRequestType,
	storeNewEntityProfileResponseType,
	StoreVExpertRequestType,
	storeVideoInteractionRequestType,
	StripePaymentResponse,
	UnSaveUsersType,
	UpdateEndorsmentsResponseType,
	updateEntityProfileResponseType,
	updateItemShippingOptionRequest,
	UpdateNoteRequestType,
	UpdateNoteResponseType,
	updatePersonalProfileLanguageRequestType,
	updatePersonalProfileRequestType,
	updatePersonalProfileResponseType,
	updatePersonalProfileSocialMediaRequestType,
	updatePersonalProfileSocialMediaResponseType,
	updatePersonalProfileStoreAttachmentsRequestType,
	updatePersonalProfileStoreAttachmentsResponseType,
	UpdateQuantityRequestType,
	UpdateQuantityResourceType,
	UpdateRoomsRequestType,
	UpdateRoomsResponseType,
	updateShippingAddressRequest,
	UpdateWishlistRequestType,
	VerifyUserIdRequestType,
	VerifyUserIdResponseType,
	WalletFundRequestType,
	WalletFundResponseType,
	WalletSubActionsRequestType,
	WalletWithdrawRequestType,
	WalletWithdrawResponseType,
	WishlistRemoveRequestType,
	WishlistRequestType,
} from '@/utils/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { endpoints } from './endpoints';
import { queryAPI } from './queries';

export const mutationAPI = createApi({
	reducerPath: 'mutationAPI',
	baseQuery: customFetchBase,
	endpoints: (build) => ({
		logout: build.mutation<LogoutResponseType, any>({
			query: () => {
				return {
					url: endpoints.logout,
					method: 'POST',
				};
			},
		}),

		updateSettings: build.mutation<any, { settings: { key: string; value: any }[] }>({
			query: (body) => {
				return {
					url: endpoints.updateAllSettings,
					method: 'POST',
					body,
				};
			},
		}),

		updateSingleSetting: build.mutation({
			query: ({ key, value, type }) => ({
				url: endpoints.updateSettings,
				method: 'POST',
				body: {
					key,
					value,
					type,
				},
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			}),
		}),
		loginUsingPhone: build.mutation<LoginByPhoneResponseType, LoginByPhoneRequestType>({
			query: (body) => {
				return {
					url: endpoints.loginUsingPhone,
					method: 'POST',
					body,
				};
			},
		}),

		// Subscribtion
		SubsribeUserPlan: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.subscribeToPlan,
					method: 'POST',
					body,
				};
			},
		}),

		ValidateCoupon: build.mutation<any, any>({
			query: (params) => {
				return {
					url: endpoints.ValidateCouponsPlans,
					method: 'GET',
					params,
				};
			},
		}),

		ValidateCouponAds: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.ValidateCouponsAds,
					method: 'POST',
					body,
				};
			},
		}),

		VNationDeleteGalleryById: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.VNationDeleteGalleryById}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		// Download files
		DownloadInvoice: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.downloadInvoice}/${id}/invoice`,
					method: 'GET',
				};
			},
		}),

		BulkdownloadInvoices: build.mutation<any, string[]>({
			query: (ids) => {
				return {
					url: `${endpoints.bulkdownloadInvoices}`,
					method: 'POST',
					body: { order_ids: ids }, // Sending the list of ids in the body
				};
			},
		}),

		removeVNationGalleryBulkUpload: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.removeVNationGalleryBulkUpload,
					method: 'POST',
					body: {
						ids: body.ids,
					},
				};
			},
		}),

		// add
		UpdateGuideCredits: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.updateGuideCredits,
					method: 'PUT',
					body,
				};
			},
		}),

		loginUsingEmail: build.mutation<LoginByEmailResponseType, any>({
			query: (body) => {
				return {
					url: endpoints.loginUsingEmail,
					method: 'POST',
					body: body,
				};
			},
		}),

		registerUsingEmail: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.registerUsingEmail,
					method: 'POST',
					body: body,
				};
			},
		}),

		registerUsingPhone: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.registerUsingPhone,
					method: 'POST',
					body: body,
				};
			},
		}),

		sendPhoneVerification: build.mutation<sendPhoneVerificationResponseType, sendPhoneVerificationRequestType>({
			query: (body) => {
				return {
					url: endpoints.sendPhoneVerification,
					method: 'POST',
					body: body,
				};
			},
		}),

		sendEmailVerification: build.mutation<sendEmailVerificationResponseType, sendEmailVerificationRequestType>({
			query: (body) => {
				return {
					url: endpoints.sendEmailVerification,
					method: 'POST',
					body: body,
				};
			},
		}),

		verifyPhoneCode: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.verifyPhoneCode,
					method: 'POST',
					body: body,
				};
			},
		}),

		verifyEmailCode: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.verifyEmailCode,
					method: 'POST',
					body: body,
				};
			},
		}),

		loginRelatedAccount: build.mutation<LoginRelatedAccountResponseType, LoginRelatedAccountResquestType>({
			query: ({ id }) => {
				return {
					url: `${endpoints.loginRelatedAccount}/${id}`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['User']));
			},
		}),

		updateOnlineStatus: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.updateOnlineStatus,
					method: 'PUT',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(
					queryAPI.util.invalidateTags([
						'User',
						'History',
						'Notifications',
						'Rooms',
						'Chats',
						'PlanMeeting',
						'MyBlogPost',
						'Jobs',
					])
				);
			},
		}),

		createBlog: build.mutation<CreateBlogResponseType, CreateBlogRequestType>({
			query: (body) => {
				return {
					url: endpoints.createBlog,
					method: 'POST',
					body,
				};
			},
		}),

		walletFund: build.mutation<WalletFundResponseType, WalletFundRequestType>({
			query: (body) => {
				return {
					url: endpoints.walletFund,
					method: 'POST',
					body,
				};
			},
		}),

		walletWithdraw: build.mutation<WalletWithdrawResponseType, WalletWithdrawRequestType>({
			query: (body) => {
				return {
					url: endpoints.walletWithdraw,
					method: 'POST',
					body,
				};
			},
		}),
		changeWalletsDefault: build.mutation<any, WalletSubActionsRequestType>({
			query: (body) => {
				return {
					url: endpoints.changeWalletsDefault,
					method: 'POST',
					body,
				};
			},
		}),
		deleteWalletById: build.mutation<any, WalletSubActionsRequestType>({
			query: (body) => {
				return {
					url: endpoints.deleteWalletById,
					method: 'DELETE',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Wallet']));
			},
		}),

		updatePersonalProfile: build.mutation<updatePersonalProfileResponseType, updatePersonalProfileRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.updatePersonalProfile}/${body.id}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Profile', 'User']));
			},
		}),

		updateBusinessProfile: build.mutation<updateEntityProfileResponseType, any>({
			query: (body) => {
				return {
					url: `${endpoints.updateBusinessProfile}/${body.id}`,
					method: 'POST',
					body,
				};
			},
		}),

		deleteShippingAddresses: build.mutation<any, deleteShippingAddressType>({
			query: ({ addressId, userId }) => {
				return {
					url: `${endpoints.deleteShippindAddress}/${userId}/addresses/${addressId}`,
					method: 'POST',
				};
			},
		}),

		deleteLanguage: build.mutation<any, deleteLanguageType>({
			query: ({ languageId, id }) => {
				return {
					url: `${endpoints.deleteLanguage}/${id}/${languageId}`,
					method: 'POST',
				};
			},
		}),

		updatePersonalProfileLanguage: build.mutation<any, updatePersonalProfileLanguageRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.updatePersonalProfile}/${body.id}/languages`,
					method: 'POST',
				};
			},
		}),

		deletePersonalProfileLanguage: build.mutation<any, deletePersonalProfileLanguageRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.updatePersonalProfile}/${body.id}/languages/${body.languageId}`,
					method: 'DELETE',
				};
			},
		}),

		updatePersonalProfileSocialMedia: build.mutation<
			updatePersonalProfileSocialMediaResponseType,
			updatePersonalProfileSocialMediaRequestType
		>({
			query: (body) => {
				return {
					url: `${endpoints.updatePersonalProfile}/${body.id}/social-media`,
					method: 'POST',
					body,
				};
			},
		}),

		deletePersonalProfileSocialMedia: build.mutation<
			deletePersonalProfileSocialMediaResponseType,
			deletePersonalProfileSocialMediaRequestType
		>({
			query: (body) => {
				return {
					url: `${endpoints.updatePersonalProfile}/${body.userId}/social-media/${body.socialMediaId}`,
					method: 'DELETE',
				};
			},
		}),

		updatePersonalProfileStoreAttachments: build.mutation<
			updatePersonalProfileStoreAttachmentsResponseType,
			updatePersonalProfileStoreAttachmentsRequestType
		>({
			query: (body) => {
				return {
					url: `${endpoints.updatePersonalProfile}/${body.id}/attachments`,
					method: 'POST',
					body,
				};
			},
		}),

		storeNewEntityProfile: build.mutation<storeNewEntityProfileResponseType, storeNewEntityProfileRequestType>({
			query: (body) => {
				return {
					url: endpoints.storeNewEntityProfile,
					method: 'POST',
					body,
				};
			},
		}),
		socialPage: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.socialPage,
					method: 'POST',
					body,
				};
			},
		}),

		createJob: build.mutation<CreateJobResponseType, CreateJobRequestType>({
			query: (body) => {
				return {
					url: endpoints.createJob,
					method: 'POST',
					body,
				};
			},
		}),
		deleteNotifications: build.mutation<any, { notification_ids: number[] }>({
			query: ({ notification_ids }) => ({
				url: `${endpoints.notificationsDelete}`,
				method: 'DELETE',
				body: { notification_ids },
			}),
		}),

		removeNotification: build.mutation<any, any>({
			query: ({ id }) => ({
				url: `${endpoints.notifications}/${id}`,
				method: 'DELETE',
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Notifications']));
			},
		}),

		notificationMute: build.mutation<any, any>({
			query: (body) => ({
				url: `${endpoints.notificationMute}`,
				method: 'POST',
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Notifications']));
			},
		}),
		notificationUnmute: build.mutation<any, any>({
			query: (body) => ({
				url: `${endpoints.notificationUnmute}`,
				method: 'POST',
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Notifications']));
			},
		}),
		sendFCMToken: build.mutation<any, any>({
			query: (body) => ({
				url: `${endpoints.sendFCMToken}`,
				method: 'POST',
				body,
			}),
		}),
		deleteHistory: build.mutation<any, { history_ids: number[] }>({
			query: ({ history_ids }) => ({
				url: `${endpoints.historyDelete}`,
				method: 'DELETE',
				body: { history_ids },
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['History']));
			},
		}),
		removeHistory: build.mutation<any, any>({
			query: ({ id }) => ({
				url: `${endpoints.history}/${id}`,
				method: 'DELETE',
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['History']));
			},
		}),

		socialGroup: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.socialGroup,
					method: 'POST',
					body,
				};
			},
		}),
		createFolder: build.mutation<any, FolderCreateRequestType>({
			query: (body) => {
				return {
					url: endpoints.createFolder,
					method: 'POST',
					body,
				};
			},
		}),
		deleteFolder: build.mutation<any, FolderDeleteRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.createFolder}/${body.id}`,
					method: 'DELETE',
				};
			},
		}),
		moveFolderToTrash: build.mutation<
			any,
			{
				token: string;
				folderId: string;
			}
		>({
			query: ({ token, folderId }) => ({
				url: `${endpoints.cloneFolder}/${folderId}/trash`,
				method: 'POST',
				params: { token },
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: {},
			}),
		}),

		renameFile: build.mutation<
			any,
			{
				token: string;
				id: string;
				name: string;
			}
		>({
			query: ({ token, id, name }) => ({
				url: `${endpoints.moveFileInTrash}/${id}/rename`,
				method: 'PUT',
				params: { token },
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: { name },
			}),
		}),

		updateFolder: build.mutation<any, FolderUpdateRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.createFolder}/${body.folder_id}`,
					method: 'PUT',
					body,
				};
			},
		}),
		moveFolder: build.mutation<any, any>({
			// query: (body) => {
			// 	return {
			// 		url: `${endpoints.createFolder}/${body.id}`,
			// 		method: 'PUT',
			// 		body,
			// 	};
			// },
			query: ({ token, folderId, folder_name, parent_folder_id }) => ({
				url: `${endpoints.createFolder}/${folderId}`,
				method: 'PUT',
				params: { token },
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: { folder_name, parent_folder_id },
			}),
		}),

		cloneFolder: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.cloneFolder}/${body.folder}/clone`,
					method: 'post',
					body,
				};
			},
		}),
		updateNote: build.mutation<UpdateNoteResponseType, UpdateNoteRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.notes}/${body.id}`,
					method: 'PUT',
					body: {
						title: body.title,
						note_details: body.note_details,
						note_date: body.note_date,
						attachments: body.attachments,
					},
				};
			},
		}),
		newNote: build.mutation<NewNoteResponseType, NewNoteRequestType>({
			query: (body) => {
				return {
					url: endpoints.notes,
					method: 'POST',
					body: {
						title: body.title,
						note_details: body.note_details,
						note_date: body.note_date,
						attachments: body.attachments,
					},
				};
			},
		}),
		deleteNote: build.mutation<DeleteNoteResponseType, DeleteNoteRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.notes}/${body.id}`,
					method: 'DELETE',
					body: {
						id: body.id,
					},
				};
			},
		}),
		uploadFoldersFiles: build.mutation<any, any>({
			query: (body) => {
				console.log(body);
				return {
					url: endpoints.foldersFiles,
					method: 'POST',
					body: body,
				};
			},
		}),
		cloneFile: build.mutation<any, { token: string; fileId: number }>({
			query: ({ token, fileId }) => ({
				url: `${endpoints.foldersFiles}/${fileId}/clone`,
				method: 'POST',
				params: { token },
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: {},
			}),
		}),
		deleteNews: build.mutation<void, DeleteNewsRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.news}/${body.id}`,
					method: 'DELETE',
					body: {
						id: body.id,
					},
				};
			},
		}),

		deleteAdRequest: build.mutation<void, DeleteAdRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.deleteAd}/${body.id}`,
					method: 'DELETE',
					body: {
						id: body.id,
					},
				};
			},
		}),

		UpdateAdStatus: build.mutation<any, any>({
			query: (body) => {
				console.log('my body', body);

				return {
					url: `${endpoints.updateAdStatus}/${body.id}`,
					method: 'PUT',
					params: { status: body.status },
					body: {
						id: body.id,
					},
				};
			},
		}),

		verifyUserId: build.mutation<VerifyUserIdResponseType, VerifyUserIdRequestType>({
			query: (body) => {
				const formData = new FormData();
				formData.append('country_id', body.country_id.toString());
				formData.append('official_full_name', body.official_full_name);
				formData.append('gender', body.gender);
				formData.append('date_of_birth', body.date_of_birth);
				formData.append('national_id_number', body.national_id_number);
				formData.append('email', body.email);
				body.attachments.forEach((file: any, index: number) => {
					formData.append(`attachments[${index}]`, file);
				});

				return {
					url: endpoints.verifyUserId,
					method: 'POST',
					body: formData,
					formData: true,
				};
			},
		}),

		myStorageFilePutInTrash: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.foldersFiles}/${body.id}/trash`,
					method: 'PUT',
				};
			},
		}),

		myStorageFileMoveToFolder: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.foldersFiles}/${body.file}/move/${body.folder}`,
					method: 'POST',
				};
			},
		}),

		welcomeFlag: build.mutation<any, any>({
			query: () => {
				return {
					url: endpoints.welcomeFlag,
					method: 'PUT',
				};
			},
		}),

		createService: build.mutation<CreateServiceResponseType, CreateServiceRequestType>({
			query: (body) => {
				return {
					url: endpoints.createService,
					method: 'post',
					body,
				};
			},
		}),

		updateService: build.mutation<{ data: FreelancingServiceResource }, CreateServiceRequestType & { id: string }>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.createService}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),

		servicePostingInteraction: build.mutation<any, getServicePostingInteractionRequestType>({
			query: (body) => {
				return {
					url: endpoints.servicePostingInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Services']));
			},
		}),

		deleteServicePostingInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteServicePostingInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Services']));
			},
		}),

		updateServicePostingInteraction: build.mutation<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.updateServicePostingInteraction}/${id}`,
					method: 'POST',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Services']));
			},
		}),

		deleteBulkNews: build.mutation<any, { asset_ids: number[] }>({
			query: ({ asset_ids }) => ({
				url: `${endpoints.newsBulkDelete}`,
				method: 'POST',
				body: { asset_ids },
			}),
		}),

		deleteBulkNotes: build.mutation<any, { asset_ids: number[] }>({
			query: ({ asset_ids }) => ({
				url: `${endpoints.notesBulkDelete}`,
				method: 'POST',
				body: { asset_ids },
			}),
		}),

		project: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.project,
					method: 'POST',
					body,
				};
			},
		}),

		// projectBulkAction
		projectBulkAction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkProjectAction,
					method: 'POST',
					body,
				};
			},
		}),

		saveProject: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.saveProjectInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Projects']));
			},
		}),

		removeProject: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.removeProjectInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Projects']));
			},
		}),

		createProjectInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createProjectInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Project']));
			},
		}),

		updateProjectInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateProjectInteraction}/${id}`,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Project']));
			},
		}),

		deleteProjectInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.destroyProjectInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Projects']));
			},
		}),

		createMessage: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createMessage,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Chats']));
			},
		}),

		storeConversationPrivateNote: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.storeConversationPrivateNote,
					method: 'POST',
					body: body,
				};
			},
		}),
		updateConversationClearOrDelete: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.updateConversationClearOrDelete,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Chats']));
			},
		}),
		updateConversationFavoriteOrHiddenOrMute: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.updateConversationFavoriteOrHiddenOrMute,
					method: 'PATCH',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Chats']));
			},
		}),
		updateUserBlock: build.mutation<any, any>({
			query: (userId) => {
				return {
					url: `${endpoints.updateUserBlock}/${userId}`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),
		createAdRequest: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createAdRequest,
					method: 'POST',
					body,
				};
			},
		}),
		createRoom: build.mutation<createRoomResponseType, createRoomRequestType>({
			query: (body) => {
				return {
					url: endpoints.roomConversation,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),
		roomsUpdateFavoriteHiddenMute: build.mutation<UpdateRoomsResponseType, UpdateRoomsRequestType>({
			query: (body) => {
				return {
					url: endpoints.roomsUpdateFavoriteHiddenMute,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),

		roomsUpdateClearDelete: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.roomsUpdateClearDelete,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),

		createScheduleMeeting: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createScheduleMeeting,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Chats']));
			},
		}),

		updateScheduleMeeting: build.mutation<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.updateScheduleMeeting}/${id}`,
					method: 'POST',
					params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Chats']));
			},
		}),

		destroyMessage: build.mutation<any, any>({
			query: (id) => {
				return { url: `${endpoints.destroyMessage}/${id}`, method: 'DELETE' };
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Chats']));
			},
		}),

		storeRoomPrivateNote: build.mutation<any, any>({
			query: (body) => {
				return { url: endpoints.roomPrivateNotes, method: 'POST', body: body };
			},
		}),

		createRoomScheduleMeeting: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createRoomScheduleMeeting,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),

		updateRoomScheduleMeeting: build.mutation<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.updateRoomScheduleMeeting}/${id}`,
					method: 'PUT',
					params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),

		updateMessage: build.mutation<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.updateMessage}/${params.id}`,
					method: 'POST',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Chats']));
			},
		}),
		//Private connection calls
		startAudioCall: build.mutation<any, any>({
			query: ({ conversationId, ...rest }) => {
				return {
					url: `${endpoints.privateConnection}/${conversationId}/audio`,
					method: 'POST',
					body: rest,
				};
			},
		}),

		initiateOfferSignal: build.mutation<any, any>({
			query: ({ callId, ...rest }) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/offer-signal`,
					method: 'POST',
					body: rest,
				};
			},
		}),

		initiateAnswerSignal: build.mutation<any, any>({
			query: ({ callId, ...rest }) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/accept-signal`,
					method: 'POST',
					body: rest,
				};
			},
		}),

		startVideoCall: build.mutation<any, any>({
			query: (conversation) => {
				return {
					url: `${endpoints.privateConnection}/${conversation}/video`,
					method: 'POST',
				};
			},
		}),

		acceptCall: build.mutation<any, any>({
			query: ({ callId, ...rest }) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/accept`,
					method: 'POST',
					body: rest,
				};
			},
		}),
		declineCall: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/decline`,
					method: 'POST',
				};
			},
		}),

		muteCall: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/mute`,
					method: 'POST',
				};
			},
		}),

		unMuteCall: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/unmute`,
					method: 'POST',
				};
			},
		}),

		turnOnCamera: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/turn-on-camera`,
					method: 'POST',
				};
			},
		}),

		turnOffCamera: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/turn-off-camera`,
					method: 'POST',
				};
			},
		}),

		participantJoined: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/participant-joined`,
					method: 'POST',
				};
			},
		}),

		participantLeft: build.mutation<any, any>({
			query: ({ callId, ...rest }) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/participant-left`,
					method: 'POST',
					body: rest,
				};
			},
		}),

		startRecording: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/start-recording`,
					method: 'POST',
				};
			},
		}),
		callEnd: build.mutation<any, any>({
			query: ({ callId, ...rest }) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/end`,
					method: 'POST',
					body: rest,
				};
			},
		}),
		stopRecording: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.privateConnection}/${callId}/stop-recording`,
					method: 'POST',
				};
			},
		}),
		createRoomMessage: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createRoomMessage,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),
		createPlannerScheduleMeeting: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.PlannerMeetings,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['PlanMeeting']));
			},
		}),
		createPlannerScheduleToDo: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.PlannerToDos,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['PlanMeeting']));
			},
		}),
		updatePlannerScheduleMeeting: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.PlannerMeetings}/${body.id}`,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['PlanMeeting']));
			},
		}),
		updatePlannerScheduleToDo: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.PlannerToDos}/${body.id}`,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['PlanMeeting']));
			},
		}),
		updateMyBlogPost: build.mutation<any, any>({
			query: (body) => {
				const { id, ...rest } = body;
				return {
					url: `${endpoints.blogPost}/update/${id}`,
					method: 'PUT',
					body: rest,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['MyBlogPost']));
			},
		}),
		updateMyEvents: build.mutation<any, any>({
			query: (body) => {
				const { id, ...rest } = body;
				return {
					url: `events/${id}`,
					method: 'PUT',
					body: rest,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Events']));
			},
		}),

		updateMyBook: build.mutation<any, any>({
			query: (body) => {
				const { id, ...rest } = body;
				return {
					url: `${endpoints.book}/update/${id}`,
					method: 'PUT',
					body: rest,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['MyBlogPost']));
			},
		}),

		deletePlan: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.Plan}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		updateRoomMessage: build.mutation<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.updateRoomMessage}/${params.id}`,
					method: 'POST',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),
		destroyRoomMessage: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.destroyRoomMessage}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),
		updateRoom: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.roomConversation}/${body.room_id}`,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),
		addRoomsParticipants: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.roomsParticipants,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),
		removeRoomsParticipants: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.roomsParticipants,
					method: 'DELETE',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),
		updateRoomsParticipantsRole: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.updateRoomsParticipantsRole,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),
		videoChannels: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.newChannel,
					method: 'POST',
					body,
				};
			},
		}),
		updateChannels: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateChannel}/${id}`,
					method: 'put',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Channels']));
			},
		}),
		videoPlaylist: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.VtubePlaylist,
					method: 'POST',
					body,
				};
			},
		}),

		roomStartAudioCall: build.mutation<any, any>({
			query: ({ callId, ...body }) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/audio`,
					method: 'POST',
					body,
				};
			},
		}),
		createEndorsment: build.mutation<any, CreateEndorsementRequestType>({
			query: (body) => {
				return {
					url: endpoints.createEndorsment,
					method: 'POST',
					body,
				};
			},
		}),

		roomStartVideoCall: build.mutation<any, any>({
			query: ({ callId, ...body }) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/video`,
					method: 'POST',
					body,
				};
			},
		}),

		createRequestEndorsment: build.mutation<
			ApproveDeclineEndorsementResponseType,
			ApproveDeclineEndorsementRequestType
		>({
			query: (body) => {
				return {
					url: endpoints.createRequestEndorsment,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'Sponsorships']));
			},
		}),

		roomOfferSignal: build.mutation<any, any>({
			query: ({ callId, ...rest }) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/offer-signal`,
					method: 'POST',
					body: rest,
				};
			},
		}),

		roomAcceptSignal: build.mutation<any, any>({
			query: ({ callId, ...rest }) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/accept-signal`,
					method: 'POST',
					body: rest,
				};
			},
		}),

		roomAccept: build.mutation<any, any>({
			query: ({ callId, ...body }) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/accept`,
					method: 'POST',
					body,
				};
			},
		}),

		updateEndorsmentById: build.mutation<UpdateEndorsmentsResponseType, any>({
			query: (body) => {
				return {
					url: `${endpoints.updateEndorsmentById}/${body.id}`,
					method: 'PUT',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'Sponsorships']));
			},
		}),

		deleteEndorsmentById: build.mutation<any, DeleteEndorsmentByIdRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.deleteEndorsmentById}/${body.id}`,
					method: 'DELETE',
					body: {
						id: body.id,
					},
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'Sponsorships']));
			},
		}),

		createEvent: build.mutation<CreateEventResponseType, CreateEventRequestType>({
			query: (body) => {
				return {
					url: endpoints.createEvent,
					method: 'POST',
					body,
				};
			},
		}),
		createEventSpeakers: build.mutation<CreateEventSpeakerResponseType, CreateEventSpeakerRequestType>({
			query: (body) => {
				return {
					url: endpoints.createEventSpeakers,
					method: 'POST',
					body,
				};
			},
		}),

		roomDeclineCall: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/decline`,
					method: 'POST',
				};
			},
		}),

		roomMute: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/mute`,
					method: 'POST',
				};
			},
		}),

		roomUnMute: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/unmute`,
					method: 'POST',
				};
			},
		}),

		roomTurnOnCamera: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/turn-on-camera`,
					method: 'POST',
				};
			},
		}),

		roomTurnOffCamera: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/turn-off-camera`,
					method: 'POST',
				};
			},
		}),

		roomParticipantJoined: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/participant-joined`,
					method: 'POST',
				};
			},
		}),

		roomParticipantLeft: build.mutation<any, any>({
			query: ({ callId, ...rest }) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/participant-left`,
					method: 'POST',
					body: rest,
				};
			},
		}),

		roomRequestRecording: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/request-recording`,
					method: 'POST',
				};
			},
		}),
		deleteProfileInteractions: build.mutation<
			// DeleteProfileInteractionResponse,
			any,
			DeleteEndorsmentByIdRequestType
		>({
			query: (body) => {
				return {
					url: `${endpoints.deleteProfileInteractions}/${body.id}`,
					method: 'DELETE',
					body: {
						id: body.id,
					},
				};
			},
		}),
		getSavedUsersByModuleAssetName: build.mutation<any, getSavedUsersRequestType>({
			query: (params) => ({
				url: endpoints.getSavedUsersByModuleAssetName,
				method: 'POST',
				body: params,
			}),
		}),
		unsaveUserByModuleAndAssetName: build.mutation<
			// UnsaveUserResponse,
			// UnsaveUserRequestType
			any,
			any
		>({
			query: (params) => ({
				url: endpoints.unsaveUserByModuleAndAssetName,
				method: 'POST',
				body: params,
			}),
		}),
		createProfileInteraction: build.mutation<any, any>({
			query: (params) => {
				return {
					url: endpoints.createProfileInteraction,
					method: 'POST',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),
		updateProfileInteraction: build.mutation<
			// UpdateProfileInteractionResponse,
			// UpdateProfileInteractionRequest
			any,
			any
		>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.updateProfileInteraction}/${id}`,
					method: 'PUT',
					body: params,
				};
			},
		}),
		birthdayPostReq: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.birthdayPostReq,
					method: 'POST',
					body,
				};
			},
		}),
		acceptOrRejectBirthdayPostReq: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.acceptOrRejectBirthdayPostReq,
					method: 'POST',
					body,
				};
			},
		}),

		hideUsersBirthdayOrAnniversary: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.hideUsersBirthdayOrAnniversary}/${body.id}`,
					method: 'POST',
					body,
				};
			},
		}),

		hideUserSuggestions: build.mutation<any, { id: string }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.hideUserSuggestions}/${id}`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),

		disconnectUser: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.disconnectUser}/${id}`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs', 'Podcasts']));
			},
		}),

		roomAcceptRecording: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/accept-recording`,
					method: 'POST',
				};
			},
		}),

		roomStartRecording: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/start-recording`,
					method: 'POST',
				};
			},
		}),

		roomStopRecording: build.mutation<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}/stop-recording`,
					method: 'POST',
				};
			},
		}),

		sendConnectionRequest: build.mutation<any, any>({
			query: (id) => {
				return {
					url: endpoints.sendConnectionRequest,
					method: 'POST',
					body: {
						receiver_id: id,
					},
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs', 'Podcasts']));
			},
		}),

		acceptOrRejectConnectionRequest: build.mutation<
			any,
			{
				request_id: number;
				connection_status: 'approved' | 'rejected';
			}
		>({
			query: (body) => {
				return {
					url: endpoints.acceptOrRejectConnectionRequest,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['User', 'Podcasts']));
			},
		}),

		deleteConnectionRequest: build.mutation<any, { id: string }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteConnectionRequest}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs', 'Podcasts']));
			},
		}),

		createOrUpdateRoomLabel: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createOrUpdateRoomLabel,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),
		createOrUpdateChatLabel: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createOrUpdateChatLabel,
					method: 'post',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Chats']));
			},
		}),

		connectBulkUsers: build.mutation<any, { user_ids: (string | number)[] }>({
			query: (body) => {
				return {
					url: endpoints.connectUsersByUsersIds,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships', 'Jobs', 'Podcasts']));
			},
		}),

		disconnectBulkUsers: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.disconnectUsersByUsersIds,
					method: 'POST',
					body: {
						user_ids: body.user_ids,
					},
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships', 'Jobs', 'Podcasts']));
			},
		}),

		BulkBlogPosts: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.BulkBlogPostsAction,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Blogs']));
			},
		}),

		blockBulkUsers: build.mutation<any, BlockAllUsersType>({
			query: (body) => {
				return {
					url: endpoints.blockUsersByUsersIds,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships', 'Jobs', 'Podcasts']));
			},
		}),

		messageBulkUsers: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkMessageUsers,
					method: 'POST',
					body: {
						user_ids: body.user_ids,
						message: body.message,
						attachments: body.attachments,
					},
				};
			},
		}),
		changeConnectionReqStatus: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.changeConnectionRequestBulkStatus,
					method: 'POST',
					body: {
						status_enum: body.status_enum,
						connection_request_ids: body.connection_request_ids,
					},
				};
			},
		}),
		acceptDeclineMeetings: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.acceptDeclineMeetings,
					method: 'POST',
					body,
				};
			},
		}),
		decideMeeting: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.decideMeeting,
					method: 'POST',
					body,
				};
			},
		}),
		storeNewEntityUser: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.storeNewEntityUser,
					method: 'POST',
					body,
				};
			},
		}),
		updateEntityUser: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.updateEntity,
					method: 'POST',
					body,
				};
			},
		}),

		deleteReferrals: build.mutation<any, any>({
			query: (referral) => {
				return {
					url: `destroy/${referral}`,
					method: 'DELETE',
				};
			},
		}),
		blogBulkInteraction: build.mutation<
			any,
			{
				status: 'archive' | 'publish' | 'remove' | 'like' | 'save' | 'unsave';
				blog_post_ids: number[];
			}
		>({
			query: (body) => {
				return {
					url: endpoints.blogBulkInteraction,
					method: 'POST',
					body,
				};
			},
		}),

		bulkInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs', 'Sponsorships', 'Podcasts', 'TimelinePosts']));
			},
		}),

		bulkProjectApplicationsAction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkProjectApplicationsAction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs', 'Sponsorships']));
			},
		}),

		unSaveBulkUser: build.mutation<any, UnSaveUsersType>({
			query: (body) => {
				return {
					url: endpoints.unSaveBulkUser,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'TimelinePosts']));
			},
		}),

		makeBulkEndorsementAction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.makeBulkEndorsementAction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'Sponsorships']));
			},
		}),

		exitRoom: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.exitRoom}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Rooms']));
			},
		}),

		report: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.report,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(
					queryAPI.util.invalidateTags([
						'Jobs',
						'Blogs',
						'Book',
						'Sponsorships',
						'Podcasts',
						'Events',
						'Services',
						'Projects',
						'Webinars',
					])
				);
			},
		}),

		updateReport: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.report,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(
					queryAPI.util.invalidateTags([
						'Jobs',
						'Blogs',
						'Book',
						'Sponsorships',
						'Podcasts',
						'Events',
						'Services',
						'Projects',
						'Webinars',
					])
				);
			},
		}),

		createJobApplication: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createJobApplication,
					method: 'POST',
					body,
				};
			},
		}),
		Creategallery: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.gallery,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Gallery']));
			},
		}),

		changeJobApplicationStatus: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.changeJobApplicationStatus,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs']));
			},
		}),

		bulkWithdrawJobApplication: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkWithdrawJobApplication,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs']));
			},
		}),

		bulkJobposting: build.mutation<getbulkJobPostingResponseType, getbulkJobPostingRequestType>({
			query: (body) => {
				return {
					url: endpoints.bulkJobposting,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Book']));
			},
		}),

		updateJobStatus: build.mutation<getJobCandidatesStatusResponseType, getJobCandidatesStatusRequestType>({
			query: (body) => {
				return {
					url: endpoints.updateJobStatus,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs']));
			},
		}),

		applyJob: build.mutation<any, getJobCandidateRequestType>({
			query: (body) => {
				return {
					url: endpoints.jobCandidates,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs']));
			},
		}),

		bookConsultation: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bookConsultation,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs']));
			},
		}),

		deleteJobApplication: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.createJobApplication}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		blockUser: build.mutation<any, EndorsmentByIdRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.blockUser}/${body.userId}`,
					method: 'POST',
					body: body,
				};
			},
		}),

		jobPostingInteraction: build.mutation<any, getJobPostingInteractionRequestType>({
			query: (body) => {
				return {
					url: endpoints.jobPostingInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs']));
			},
		}),

		bookInteration: build.mutation<any, getBookInterationRequest>({
			query: (body) => {
				return {
					url: endpoints.bookInteractions,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Book']));
			},
		}),

		bulkBlogAction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkBlogAction,
					method: 'POST',
					body: body,
				};
			},
		}),
		deleteBlogPost: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteBlogPost}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		deleteBook: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteBook}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		unBlockUser: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.unBlockUser}/${id}`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		createEndorsments: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createEndorsments,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		unsaveJob: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.unsaveJob}/${id}`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs']));
			},
		}),

		createBlogPostInteractions: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createBlogPostInteractions,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Blogs']));
			},
		}),

		createBookInteractions: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createBookInteractions,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Book']));
			},
		}),

		createConsultationInteractions: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createConsultationInteractions,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Consultation']));
			},
		}),

		createEventPostInteractions: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createEvent,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Events']));
			},
		}),

		updateBlogPostInteractions: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateBlogPostInteraction}/${id}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Blogs']));
			},
		}),

		updateBookInteractions: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateBookInteraction}/${id}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Book']));
			},
		}),

		updateConsultationInteractions: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateConsultationInteraction}/${id}`,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Consultation']));
			},
		}),

		updateEventPostInteractions: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.getMyEventsAssets}/${id}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Events']));
			},
		}),

		deleteBlogPostInteractions: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.blogPostinterations}/${id}`,
					method: 'DELETE',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Blogs']));
			},
		}),

		updateJobPosting: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.getJobPosting}/${id}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Blogs']));
			},
		}),

		bulkJobCandidateStatusUpdate: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkJobCandidateStatusUpdate,
					method: 'POST',
					body,
				};
			},
		}),

		sendEntityPhoneVerification: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.sendEntityPhoneVerification,
					method: 'POST',
					body: body,
				};
			},
		}),

		sendEntityEmailVerification: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.sendEntityEmailVerification,
					method: 'POST',
					body: body,
				};
			},
		}),

		verifyEntityPhoneCode: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.verifyEntityPhoneCode,
					method: 'POST',
					body: body,
				};
			},
		}),

		verifyEntityEmailCode: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.verifyEntityEmailCode,
					method: 'POST',
					body: body,
				};
			},
		}),

		bulkJobInvite: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.bulkJobInvite}/${id}`,
					method: 'POST',
					body: body,
				};
			},
		}),

		emojisStore: build.mutation<any, any>({
			query: (params) => {
				return {
					url: endpoints.emojisStore,
					method: 'POST',
					body: params,
				};
			},
		}),
		bulkHideBirthdayAnniversary: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkHideBirthdayAnniversary,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['User']));
			},
		}),
		hideBirthdayAnniversary: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.hideBirthdayAnniversary}/${id}`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['User']));
			},
		}),
		updateJobPostingInteraction: build.mutation<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.updateJobPostingInteraction}/${id}`,
					method: 'POST',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs']));
			},
		}),
		deleteJobPostingInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteJobPostingInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs']));
			},
		}),
		bulkHideSuggestedUsers: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkHideSuggestedUsers,
					method: 'POST',
					body,
				};
			},
		}),
		unsaveBlogPost: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.unsaveBlogPost}/${id}/remove`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Blogs']));
			},
		}),

		unsaveBook: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.unsaveBook}/${id}/remove`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Book']));
			},
		}),

		createBook: build.mutation<CreateBookResponseType, CreateBookRequestType>({
			query: (body) => {
				return {
					url: endpoints.createBook,
					method: 'POST',
					body,
				};
			},
		}),

		deleteWorkExperience: build.mutation<any, { workExperience: string }>({
			query: ({ workExperience }) => {
				return {
					url: `${endpoints.deleteWorkExperience}/${workExperience}`,
					method: 'DELETE',
				};
			},
		}),
		deleteAchievement: build.mutation<any, { achievement: string }>({
			query: ({ achievement }) => {
				return {
					url: `${endpoints.deleteAchievement}/${achievement}`,
					method: 'DELETE',
				};
			},
		}),
		deleteEducation: build.mutation<any, { education: string }>({
			query: ({ education }) => {
				return {
					url: `${endpoints.deleteEducation}/${education}`,
					method: 'DELETE',
				};
			},
		}),
		deleteSkillSet: build.mutation<any, { skillset: string }>({
			query: ({ skillset }) => {
				return {
					url: `${endpoints.deleteSkillSet}/${skillset}`,
					method: 'DELETE',
				};
			},
		}),
		deleteSkill: build.mutation<any, { skill: string }>({
			query: ({ skill }) => {
				return {
					url: `${endpoints.deleteSkill}/${skill}`,
					method: 'DELETE',
				};
			},
		}),
		deletePersonalSocialLinks: build.mutation<any, { link: string }>({
			query: ({ link }) => {
				return {
					url: `${endpoints.deletePersonalSocialLinks}/${link}`,
					method: 'DELETE',
				};
			},
		}),
		deleteBusinessSocialLink: build.mutation<any, { link: string }>({
			query: ({ link }) => {
				return {
					url: `${endpoints.deleteBusinessSocialLink}/${link}`,
					method: 'DELETE',
				};
			},
		}),
		deleteBusinessSocialBranchLink: build.mutation<any, { link: string }>({
			query: ({ link }) => {
				return {
					url: `${endpoints.deleteBusinessSocialBranchLink}/${link}`,
					method: 'DELETE',
				};
			},
		}),

		sponsorShipStore: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.sponsorShipStore,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		createSponsorshipInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createSponsorshipInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		updateSponsorshipInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateSponsorshipInteraction}/${id}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		deleteSponsorshipInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.deleteSponsorshipInteraction}/${id}`,
					method: 'DELETE',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		bulkJobPostingUpdate: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkJobPostingUpdate,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Jobs']));
			},
		}),

		updateSponsorShipById: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateSponsorShipById}/${id}`,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		updateBookById: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateBookById}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),

		unSaveEvent: build.mutation<any, any>({
			query: ({ id }) => ({
				url: `${endpoints.unSaveEvent}/${id}`,
				method: 'POST',
			}),
		}),

		unsaveSponsorship: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.unsaveSponsorship}/${id}/remove`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		unsaveCourse: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.unsaveCourse}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		courseInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.courseInteraction}`,
					method: 'POST',
					params: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['College']));
			},
		}),

		unitLessonInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.unitLessonInteraction}`,
					method: 'POST',
					params: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['College']));
			},
		}),

		updateCourseInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.courseInteraction}/${id}/update`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['College']));
			},
		}),

		updateUnitLessonInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.unitLessonInteraction}/${id}/update`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['College']));
			},
		}),

		deleteCourseInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.courseInteraction}/${id}`,
					method: 'DELETE',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['College']));
			},
		}),

		deleteUnitLessonInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.unitLessonInteraction}/${id}`,
					method: 'DELETE',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['College']));
			},
		}),

		deleteEventById: build.mutation<any, any>({
			query: (id) => ({
				url: `${endpoints.deleteEventById}/${id}`,
				method: 'DELETE',
			}),
		}),

		podcastStore: build.mutation<any, any>({
			query: (body) => ({
				url: endpoints.podcastStore,
				method: 'POST',
				body,
			}),
		}),

		removeSavedCastInteraction: build.mutation<any, any>({
			query: ({ id, ...params }) => ({
				url: `${endpoints.removeSavedCastInteraction}/${id}/remove`,
				method: 'DELETE',
				params: params,
			}),

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'SimilarPodcasts']));
			},
		}),

		consultationInteraction: build.mutation<any, any>({
			query: (body) => ({
				url: `${endpoints.consultationInteraction}`,
				method: 'POST',
				body,
			}),

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Consultation']));
			},
		}),

		serviceInteraction: build.mutation<any, any>({
			query: (body) => ({
				url: `${endpoints.serviceInteractions}`,
				method: 'POST',
				body,
			}),

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Service']));
			},
		}),

		projectInteraction: build.mutation<any, any>({
			query: (body) => ({
				url: `${endpoints.createProjectInteraction}`,
				method: 'POST',
				body,
			}),

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Projects']));
			},
		}),

		projectUnsave: build.mutation<any, any>({
			query: ({ project_id, ...rest }) => ({
				url: `${endpoints.removeFromSavedProjectInteraction}/${project_id}`,
				method: 'DELETE',
				rest,
			}),

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Projects']));
			},
		}),

		createCastInteraction: build.mutation<any, any>({
			query: (params) => ({
				url: endpoints.createCastInteraction,
				method: 'POST',
				body: params,
			}),

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'SimilarPodcasts']));
			},
		}),

		updateCastInteraction: build.mutation<any, any>({
			query: ({ id, ...params }) => ({
				url: `${endpoints.updateCastInteraction}/${id}`,
				method: 'POST',
				body: params,
			}),

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),

		updateCastEpisodeInteraction: build.mutation<any, any>({
			query: ({ id, ...params }) => ({
				url: `${endpoints.updateCastEpisodeInteraction}/${id}`,
				method: 'POST',
				body: params,
			}),

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),

		deleteCastEpisodeSaved: build.mutation<any, any>({
			query: ({ id, ...params }) => ({
				url: `${endpoints.deleteCastEpisodeSaved}/${id}/remove`,
				method: 'DELETE',
				body: params,
			}),

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'SimilarPodcasts']));
			},
		}),

		deleteCastInteraction: build.mutation<any, any>({
			query: (id) => ({
				url: `${endpoints.deleteCastInteraction}/${id}`,
				method: 'DELETE',
			}),

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),
		deleteCoupon: build.mutation<any, any>({
			query: (id) => ({
				url: `${endpoints.deleteCoupon}/${id}`,
				method: 'DELETE',
			}),
		}),
		deleteBookAttachment: build.mutation<any, any>({
			query: (id) => ({
				url: `${endpoints.vBooksBook}/${id}/attachment`,
				method: 'DELETE',
			}),
		}),

		updatePodcasts: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updatePodcasts}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),

		wishlist: build.mutation<any, WishlistRequestType>({
			query: (body) => {
				return {
					url: endpoints.wishlist,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships', 'Podcasts', 'SimilarPodcasts']));
			},
		}),

		wishlistRemove: build.mutation<any, WishlistRemoveRequestType>({
			query: (body) => {
				return {
					url: endpoints.wishlistRemove,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships', 'Podcasts', 'SimilarPodcasts']));
			},
		}),

		sponsorshipBulkAction: build.mutation<any, SponsorshipBulkActionRequestType>({
			query: (body) => {
				return {
					url: endpoints.sponsorshipBulkAction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),
		unsaveStoreBulkAction: build.mutation<any, SponsorshipBulkActionRequestType>({
			query: (body) => {
				return {
					url: endpoints.unSavestore,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		collegeBulkAction: build.mutation<any, CollegeBulkActionRequestType>({
			query: (body) => {
				return {
					url: endpoints.collegeBulkAction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['College']));
			},
		}),

		serviceBulkAction: build.mutation<any, SponsorshipBulkActionRequestType>({
			query: (body) => {
				return {
					url: endpoints.serviceBulkAction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		deleteBookInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteBookInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Book']));
			},
		}),

		deleterGallery: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.gallery}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Gallery']));
			},
		}),

		deleteConsultationInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteConsultationInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Consultation']));
			},
		}),

		bulkRemoveBookFromDownloads: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkRemoveBookFromDownloads,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Book']));
			},
		}),

		bulkDownloadBooks: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkDownloadBooks,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Book']));
			},
		}),

		addCart: build.mutation<any, AddCartRequest>({
			query: (body) => {
				return {
					url: endpoints.addCart,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'SimilarPodcasts']));
			},
		}),

		removeCart: build.mutation<any, removeCartRequest>({
			query: (body) => {
				return {
					url: endpoints.removeCart,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Cart', 'Podcasts', 'SimilarPodcasts']));
			},
		}),

		updateShippingAddress: build.mutation<any, updateShippingAddressRequest>({
			query: (body) => {
				return {
					url: endpoints.updateShippingAddress,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Cart']));
			},
		}),
		updateItemShippingOption: build.mutation<any, updateItemShippingOptionRequest>({
			query: (body) => {
				return {
					url: endpoints.updateItemShippingOption,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Cart']));
			},
		}),

		bulkBookAction: build.mutation<any, bookBulkInteractionRequestType>({
			query: (body) => {
				return {
					url: endpoints.bulkBookAction,
					method: 'POST',
					body,
				};
			},
		}),
		bulkConsultationAction: build.mutation<any, consultationBulkInteractionRequestType>({
			query: (body) => {
				return {
					url: endpoints.bulkConsultationAction,
					method: 'POST',
					body,
				};
			},
		}),
		createEventInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createEventInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Events']));
			},
		}),
		updateEventInteraction: build.mutation<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.updateEventInteraction}/${id}`,
					method: 'POST',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Events']));
			},
		}),
		deleteEventInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteEventInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Events']));
			},
		}),
		bulkSaveOrUnsaveOrLikeEvents: build.mutation<any, any>({
			query: (params) => {
				return {
					url: endpoints.bulkSaveOrUnsaveOrLikeEvents,
					method: 'POST',
					params: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Events']));
			},
		}),

		createConsultation: build.mutation<any, StoreVExpertRequestType>({
			query: (body) => {
				return {
					url: endpoints.storeVExpert,
					method: 'POST',
					body,
				};
			},
		}),
		updateConsultation: build.mutation<any, StoreVExpertRequestType & { id: string }>({
			query: (body) => {
				return {
					url: `${endpoints.storeVExpert}/${body.id}`,
					method: 'PUT',
					body,
				};
			},
		}),

		bulkRemoveDownloadTicket: build.mutation<any, any>({
			query: (params) => {
				return {
					url: endpoints.bulkRemoveDownloadTicket,
					method: 'POST',
					body: params,
				};
			},
		}),

		bulkDownloadTickets: build.mutation<any, any>({
			query: (params) => {
				return {
					url: endpoints.bulkDownloadTickets,
					method: 'POST',
					body: params,
				};
			},
		}),

		bulkPodcastAction: build.mutation<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.bulkPodcastAction}`,
					method: 'POST',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'SimilarPodcasts']));
			},
		}),

		bulkDeletePodcasts: build.mutation<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getPodcasts}/${params.id}`,
					method: 'DELETE',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),

		bulkEpisodesAction: build.mutation<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.bulkEpisodesAction}`,
					method: 'POST',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'SimilarPodcasts']));
			},
		}),

		sponsorshipContactTier: build.mutation<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.sponsorshipContactTier}`,
					method: 'POST',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		bulkSubscribeOrUnsubscribePodcasts: build.mutation<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.bulkSubscribeOrUnsubscribePodcasts}`,
					method: 'POST',
					body: params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),

		createEpisodeInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createEpisodeInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'SimilarPodcasts']));
			},
		}),

		updateEpisodeInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateEpisodeInteraction}/${id}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),

		deleteEpisodeInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.deleteEpisodeInteraction}/${id}`,
					method: 'DELETE',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),

		//  need to add onQueryStarted vtube-channel
		bulkSaveOrUnsaveOrLikeChannels: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkSaveOrUnsaveOrLikeChannels,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Channels']));
			},
		}),

		subscribeToChannel: build.mutation({
			query: ({ channelId }) => ({
				url: `${endpoints.updateChannel}/${channelId}/subscribe`,
				method: 'POST',
				body: {},
			}),
		}),

		subscribeChannel: build.mutation({
			query: ({ channelId }: { channelId: string }) => ({
				url: `${endpoints.updateChannel}/${channelId}/subscribe`,
				method: 'POST',
				body: {},
			}),
		}),

		unsubscribeFromChannel: build.mutation({
			query: ({ channelId }) => ({
				url: `${endpoints.updateChannel}/${channelId}/unsubscribe`,
				method: 'DELETE',
			}),
		}),

		unsubscribeChannel: build.mutation({
			query: ({ channelId }: { channelId: string }) => ({
				url: `${endpoints.updateChannel}/${channelId}/unsubscribe`,
				method: 'DELETE',
			}),
		}),

		addToQueueVideos: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.addToQueueVideo,
					method: 'POST',
					body,
				};
			},
		}),

		//channel Interactions
		createChannelInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createChannelInteraction,
					method: 'POST',
					body,
				};
			},
		}),

		updateChannelInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateChannelInteraction}/${id}/update`,
					method: 'POST',
					body,
				};
			},
		}),

		deleteChannelComment: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.deleteChannelComment}/${id}`,
					method: 'DELETE',
					body,
				};
			},
		}),

		// Add to Watch Later
		addToWatchLater: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `${endpoints.addToWatchLaterVideo}/${data.id}`,
					method: 'POST',
				};
			},
		}),

		// notify me about channel
		notifyAboutChannel: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.notifyAboutChannel}/${id}/notify`,
					method: 'POST',
				};
			},
		}),

		dontNotifyAboutChannel: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.dontNotifyAboutChannel}/${id}/unNotify`,
					method: 'DELETE',
				};
			},
		}),

		addToPlaylistVTube: build.mutation<any, { playlist_id: number; video_id: number }>({
			query: ({ playlist_id, video_id }) => {
				return {
					url: `${endpoints.addToPlaylistVTube}/${playlist_id}/video`,
					method: 'POST',
					body: { video_id }, // Send the video ID in the body
				};
			},
		}),

		// create video interaction in vv-search vidoes id comment
		createVideoInteraction: build.mutation<any, storeVideoInteractionRequestType>({
			query: (body) => {
				return {
					url: endpoints.createVideoInteraction,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Videos']));
			},
		}),

		deleteVidoeInteraction: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteVideoInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Videos']));
			},
		}),

		updateVideoInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateVideoInteraction}/${id}/update`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Videos']));
			},
		}),
		eventBulkDelete: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.eventBulkDelete,
					method: 'POST',
					body: {
						ids: body.ids,
					},
				};
			},
		}),
		eventBulkPublish: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.eventBulkPublish,
					method: 'POST',
					body: {
						ids: body.ids,
					},
				};
			},
		}),
		eventBulkArchive: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.eventBulkArchive,
					method: 'POST',
					body: {
						ids: body.ids,
					},
				};
			},
		}),
		eventBulkDraft: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.eventBulkDraft,
					method: 'POST',
					body: {
						ids: body.ids,
					},
				};
			},
		}),

		podcastEpisodeStore: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.podcastEpisodeStore,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),

		unsaveConsultation: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.unsaveConsultation}/${id}`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Consultation']));
			},
		}),

		bulkSaveOrUnsaveOrLikeVideos: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkSaveOrUnsaveOrLikeVideos,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Videos']));
			},
		}),

		// bulk save/unsave/like shorts
		bulkSaveOrUnsaveOrLikeShorts: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkSaveOrUnsaveOrLikeShorts,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Shorts']));
			},
		}),
		deleteStoreById: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteStoreById}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		createStore: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createStore,
					method: 'POST',
					body,
				};
			},
		}),
		updateStoreById: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateStoreById}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),
		savedRemoveStoreInteraction: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.savedRemoveStoreInteraction}/${id}/remove`,
					method: 'DELETE',
				};
			},
		}),
		deleteStoreInteraction: build.mutation<any, any>({
			query: ({ StoreId }) => {
				return {
					url: `${endpoints.deleteStoreInteraction}/${StoreId}`,
					method: 'DELETE',
				};
			},
		}),
		createStoreInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createStoreInteraction,
					method: 'POST',
					body,
				};
			},
		}),
		updateStoreInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateStoreInteraction}/${id}`,
					method: 'POST',
					body,
				};
			},
		}),

		createServiceInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createServiceInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Services']));
			},
		}),
		updateServiceInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateServiceInteraction}/${id}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Services']));
			},
		}),
		deleteServiceInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.deleteServiceInteraction}/${id}`,
					method: 'DELETE',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Services']));
			},
		}),
		unsaveService: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.unsaveService}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Services']));
			},
		}),
		destroyFreelancingService: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.destroyFreelancingService}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		destroyFreelancingProject: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.destroyFreelancingProject}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		bulkFreelanceServiceAction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkFreelanceServiceAction,
					method: 'POST',
					body: body,
				};
			},
		}),

		projectUpdate: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.projectUpdate}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),
		getApplicantsByProjectId: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.getApplicantsByProjectId,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Project']));
			},
		}),

		createWebinarInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createWebinarInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Webinars']));
			},
		}),
		updateWebinarInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateWebinarInteraction}/${id}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Webinars']));
			},
		}),
		deleteWebinarInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteWebinarInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Webinars']));
			},
		}),
		unsaveWebinar: build.mutation<any, any>({
			query: ({ webinar_id }) => {
				return {
					url: `${endpoints.unsaveWebinar}/${webinar_id}/remove`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Webinars']));
			},
		}),

		webinarStore: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.webinarStore,
					method: 'POST',
					body: body,
				};
			},
		}),

		webinarUpdate: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.webinarUpdate}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),

		galleryAttechmentRemoveManyByIds: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.galleryAttechmentRemoveManyByIds}`,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Gallery']));
			},
		}),

		galleryAttachmentUpdate: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.gallery}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Gallery']));
			},
		}),

		deleteCouponWebinar: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteCouponWebinar}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		applyProject: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.applyProject,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Projects']));
			},
		}),

		updateProjectStatus: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.updateProjectStatus,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Projects']));
			},
		}),

		storeCourse: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.storeCourse}`,
					method: 'POST',
					body: body,
				};
			},
		}),

		updateCourse: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateCourse}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),
		storeCourseUnit: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.storeCourseUnit}`,
					method: 'POST',
					body: body,
				};
			},
		}),
		deleteCourseUnit: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteCourseUnit}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		deleteCourseUnitLesson: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteCourseUnitLesson}/${id}/attachment`,
					method: 'DELETE',
				};
			},
		}),
		updateCourseUnit: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateCourseUnit}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),
		storeQuizUnit: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.storeQuizUnit}`,
					method: 'POST',
					body: body,
				};
			},
		}),
		updateQuizUnit: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateQuizUnit}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),
		deleteQuizUnit: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteQuizUnit}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		deleteWebinarById: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getWebinarDetailsById}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		deletePlaylist: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deletePlaylist}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		bulkWebinarAction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkWebinarAction,
					method: 'POST',
					body,
				};
			},
		}),

		updatePlaylist: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updatePlaylist}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),

		storeExam: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.storeExam}`,
					method: 'POST',
					body: body,
				};
			},
		}),
		updateExam: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateExam}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),
		deleteExam: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteExam}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		changeProjectApplicationStatus: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.changeProjectApplicationStatus,
					method: 'POST',
					body,
				};
			},
		}),

		deleteProjectApplication: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteProjectApplication}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		bulkProjectApplicantStatusUpdate: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkProjectApplicantStatusUpdate,
					method: 'POST',
					body,
				};
			},
		}),

		storeAnnouncement: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.storeAnnouncement}`,
					method: 'POST',
					body: body,
				};
			},
		}),
		updateAnnouncement: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateAnnouncement}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),

		deleteAnnouncement: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteAnnouncement}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		createSocialTimelinePostInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createSocialTimelinePostInteraction,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		updateSocialTimelinePostInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateSocialTimelinePostInteraction}/${id}/update`,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		deleteSocialTimelinePostInteracion: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteSocialTimelinePostInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		removeSocialTimelinePostInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.removeSocialTimelinePostInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		muteSocialPagePost: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.createSocialPagePost}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts']));
			},
		}),

		updateSocialPagePost: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.createSocialPagePost}/${id}`,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts']));
			},
		}),

		muteSocialGroupPost: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.createSocialGroupPost}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialGroupPosts']));
			},
		}),

		updateSocialGroupPost: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.createSocialGroupPost}/${id}`,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialGroupPosts']));
			},
		}),

		muteSocialTimelinePost: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.muteSocialTimelinePost}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		updateSocialTimelinePost: build.mutation<any, any>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.muteSocialTimelinePost}/${id}`,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		publishSocialTimelinePost: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.muteSocialTimelinePost}/publish/${id}`,
					method: 'PUT',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		unmuteSocialTimelinePost: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.unmuteSocialTimelinePost}/${id}/unmute`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		toggleMuteSocialTimelinePost: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.toggleMuteSocialTimelinePost}/${id}/toggle-mute`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		updateFlagsForSocialTimelinePost: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateFlagsForSocialTimelinePost}/${id}/update-flags`,
					method: 'PATCH',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		// social page interactions
		createSocialPageInteraction: build.mutation<any, any>({
			query: ({ ...body }) => {
				return {
					url: endpoints.createSocialPageInteraction,
					method: 'POST',
					body,
				};
			},
		}),

		updateSocialPageInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateSocialPageInteraction}/${id}/update`,
					method: 'POST',
					body,
				};
			},
		}),

		deleteSocialPageInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteSocialPageInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts']));
			},
		}),

		bulkSocialPageInteraction: build.mutation<any, any>({
			query: ({ ...body }) => {
				return {
					url: endpoints.bulkSocialPageInteraction,
					method: 'POST',
					body,
				};
			},
		}),

		bulkSocialPageAction: build.mutation<any, any>({
			// for vv - search social pages
			query: ({ ...body }) => {
				return {
					url: endpoints.bulkSocialPageAction,
					method: 'POST',
					body,
				};
			},
		}),

		bulkActionSocialTimelinePost: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkActionSocialTimelinePost,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		bulkAcrossSocialPost: build.mutation<any, any>({
			query: ({ ...body }) => {
				return {
					url: endpoints.bulkAcrossSocialPost,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts', 'socialPagePosts', 'socialGroupPosts']));
			},
		}),

		bulkActionSocialPagePost: build.mutation<any, any>({
			query: ({ ...body }) => {
				return {
					url: endpoints.bulkActionSocialPagePost,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts']));
			},
		}),

		storeStore: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.storeStore}`,
					method: 'POST',
					body: body,
				};
			},
		}),

		updateStore: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateStore}/${id}`,
					method: 'POST',
					body: body,
				};
			},
		}),

		deleteSocialPage: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteSocialPage}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		removeTrackerHistory: build.mutation<any, any>({
			query: ({ videoId }) => ({
				url: `${endpoints.removeSingleSubscriptions}/${videoId}`,
				method: 'DELETE',
			}),
		}),

		deleteSocialGroup: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteSocailGroup}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		bulkSocialGroupInteraction: build.mutation<any, any>({
			query: ({ ...body }) => {
				return {
					url: endpoints.bulkSocialGroupInteraction,
					method: 'POST',
					body,
				};
			},
		}),

		// socialGroupInteraction
		createSocialGroupInteraction: build.mutation<any, any>({
			query: ({ ...body }) => {
				return {
					url: endpoints.createSocialGroupInteraction,
					method: 'POST',
					body,
				};
			},
		}),

		createVNationPostGallery: build.mutation<any, any>({
			query: ({ ...body }) => {
				return {
					url: endpoints.VNationPostGallery,
					method: 'POST',
					body,
				};
			},
		}),

		updateVNationPostGallery: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.VNationPostGallery}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),

		addVoteToPollAnswer: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.addVoteToPollAnswer}/${id}`,
					method: 'POST',
					body: {},
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		updateSocialGroupInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateSocialGroupInteraction}/${id}/update`,
					method: 'POST',
					body,
				};
			},
		}),

		deleteSocialGroupInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteSocialGroupInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialGroupPosts']));
			},
		}),

		removeFromSavedUnsaveGroup: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.removeFromSavedUnsaveGroup}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		removeFromSavedUnsaveSocialPage: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.removeFromSavedUnsaveSocialPage}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		joinGroupVNation: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.joinGroupVNation}/${id}`,
					method: 'POST',
				};
			},
		}),

		leaveGroupVNation: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.leaveGroupVNation}/${id}`,
					method: 'POST',
				};
			},
		}),

		// for pages
		followPageVNation: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.followPageVNation}/${id}/follow`,
					method: 'POST',
				};
			},
		}),

		unfollowPageVNation: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.unfollowPageVNation}/${id}/unfollow`,
					method: 'POST',
				};
			},
		}),

		unfollowPageVNation2: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.unfollowPageVNation2}/${id}/unfollow`,
					method: 'POST',
				};
			},
		}),

		addWishlist: build.mutation<any, AddWishlistRequestType>({
			query: (body) => {
				return {
					url: endpoints.addWishlist,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Wishlists']));
			},
		}),

		updateWishlist: build.mutation<any, UpdateWishlistRequestType>({
			query: (body) => {
				return {
					url: endpoints.updateWishlist,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Wishlists']));
			},
		}),

		RemoveWishlistItems: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.removeWishlistItems,
					method: 'POST',
					body: body,
				};
			},
		}),

		removeWishlist: build.mutation<any, RemoveWishlistRequestType>({
			query: (body) => {
				return {
					url: endpoints.removeWishlist,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Wishlists']));
			},
		}),

		removeBulkWishlistItem: build.mutation<any, RemoveBulkWishlistRequestType>({
			query: (body) => {
				return {
					url: endpoints.removeBulkWishlistItem,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Wishlists']));
			},
		}),

		deleteCourseCoupon: build.mutation<any, deleteShippingAddressType>({
			query: (id) => {
				return {
					url: `${endpoints.deleteCourseCoupon}/${id}/coupon`,
					method: 'DELETE',
				};
			},
		}),

		contractServiceTier: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.contractServiceTier,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Services']));
			},
		}),

		changeDefaultWishlist: build.mutation<any, ChangeDefaultWishlistRequestType>({
			query: (body) => {
				return {
					url: endpoints.changeDefaultWishlist,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Wishlists']));
			},
		}),

		moveBulkWishlist: build.mutation<any, moveBulkWishlistRequestType>({
			query: (body) => {
				return {
					url: endpoints.moveBulkWishlist,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Wishlists']));
			},
		}),
		savedProduct: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.savedProduct}`,
					method: 'POST',
					body: body,
				};
			},
		}),

		unSavedProduct: build.mutation<any, any>({
			query: ({ ProductId }) => {
				return {
					url: `${endpoints.unSavedProduct}/${ProductId}/remove`,
					method: 'DELETE',
				};
			},
		}),

		productBulkInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.productBulkInteraction}`,
					method: 'POST',
					body: body,
				};
			},
		}),

		storeBulkActions: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.storeBulkActions,
					method: 'POST',
					body: body,
				};
			},
		}),
		createProductInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createProductInteraction,
					method: 'POST',
					body: body,
				};
			},
		}),
		bulkProductInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkProductInteraction,
					method: 'POST',
					body: body,
				};
			},
		}),
		deleteProductInteraction: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteProductInteraction}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		examSubmission: build.mutation<any, any>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.examSubmission}/${id}`,
					method: 'POST',
					body: body,
				};
			},
		}),
		quizSubmission: build.mutation<any, any>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.quizSubmission}/${id}`,
					method: 'POST',
					body: body,
				};
			},
		}),
		updateProductInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateProductInteraction}/${id}`,
					method: 'POST',
					body: body,
				};
			},
		}),
		storeCourseInstructor: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.storeCourseInstructor,
					method: 'POST',
					body: body,
				};
			},
		}),

		deleteSponsorship: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getSponsorShipById}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		createDeliverySubmissionReq: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createDeliverySubmission,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		cancelContractReq: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.cancelContract}/${id}`,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		getAllProductPosts: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.getAllProductPosts,
					method: 'POST',
					body: body,
				};
			},
		}),

		updateSocialPage: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateSocialPage}/${id}`,
					method: 'PUT',

					body: body,
				};
			},
		}),

		updateSocialGroup: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateSocialGroup}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),

		addToCart: build.mutation<AddToCartResponseType, AddToCartRequestType>({
			query: (body) => {
				return {
					url: endpoints.addToCart,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Cart']));
			},
		}),
		removeFromCart: build.mutation<RemoveFromCartResponseType, RemoveFromCartRequestType>({
			query: (body) => {
				return {
					url: endpoints.removeFromCart,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Cart']));
			},
		}),
		updateItemQuantity: build.mutation<UpdateQuantityResourceType, UpdateQuantityRequestType>({
			query: (body) => {
				return {
					url: endpoints.updateItemQuantity,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Cart']));
			},
		}),
		applyCoupon: build.mutation<ApplyCouponResponseType, ApplyCouponRequestType>({
			query: (body) => {
				return {
					url: endpoints.applyCoupon,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Cart', 'Coupons']));
			},
		}),
		removeCoupon: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.removeCoupon,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Cart', 'Coupons']));
			},
		}),
		checkout: build.mutation<CheckoutResponseType, any>({
			query: (body) => {
				return {
					url: endpoints.checkout,
					method: 'POST',
					body: body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Cart']));
			},
		}),
		moveFileInTrash: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.moveFileInTrash}/${id}/trash`,
					method: 'PUT',
				};
			},
		}),
		moveBulkFilesInTrash: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.moveBulkFilesInTrash}`,
					method: 'POST',
					body,
				};
			},
		}),
		restoreFromTrash: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.restoreBulkFiles}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['folders']));
			},
		}),
		moveBulkFoldersInTrash: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.moveBulkFoldersInTrash}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['folders']));
			},
		}),
		moveBulkFilesInFolder: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.moveBulkFilesInFolder}`,
					method: 'POST',
					body,
				};
			},
		}),
		deleteFile: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteFile}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		restoreFile: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteFile}/${id}/restore`,
					method: 'PUT',
				};
			},
		}),
		bulkDownloadProducts: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.bulkDownloadProducts,
					method: 'POST',
					body,
				};
			},
		}),

		unSaveEpisode: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.unSaveEpisode}/${id}/remove`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),

		removeEpisodeById: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.episodesById}/${id}`,
					method: 'DELETE',
				};
			},

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts']));
			},
		}),

		deleteInstructorsById: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteInstructorsById}/${id}/instructor`,
					method: 'DELETE',
				};
			},
		}),

		createShared: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.shareCreate}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(
					queryAPI.util.invalidateTags([
						'Podcasts',
						'Sponsorships',
						'TimelinePosts',
						'socialPagePosts',
						'socialGroupPosts',
					])
				);
			},
		}),

		storeNote: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.storeNote}`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Notes']));
			},
		}),
		unsaveConsultationInteraction: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.unsaveConsultationInteraction}/${id}`,
					method: 'POST',
				};
			},
		}),

		deleteSocialPagePost: build.mutation<any, any>({
			// for deleting highlight/reel/action if post_entity : 'SocialPagePost'
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteSocialPagePost}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts']));
			},
		}),

		deleteSocialGroupPost: build.mutation<any, any>({
			// for deleting highlight/reel/action if post_entity : 'SocialGroupPost'
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteSocialGroupPost}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialGroupPosts']));
			},
		}),

		deleteSocialTimelinePost: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteSocialTimelinePost}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['TimelinePosts']));
			},
		}),

		createSocialPagePostInteraction: build.mutation<any, any>({
			query: ({ ...body }) => {
				return {
					url: endpoints.createSocialPagePostInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts', 'TimelinePosts']));
			},
		}),

		updateSocialPagePostInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateSocialPagePostInteraction}/${id}/update`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts']));
			},
		}),

		deleteSocialPagePostInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteSocialPagePostInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts']));
			},
		}),

		removeSocialPagePostInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.removeSocialPagePostInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts']));
			},
		}),

		toggleMuteSocialPagePost: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.toggleMuteSocialPagePost}/${id}/toggle-author-mute`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts']));
			},
		}),

		updateFlagsForSocialPagePost: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateFlagsForSocialPagePost}/${id}/update-flags`,
					method: 'PATCH',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialPagePosts']));
			},
		}),

		createSocialGroupPostInteraction: build.mutation<any, any>({
			query: ({ ...body }) => {
				return {
					url: endpoints.createSocialGroupPostInteraction,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialGroupPosts']));
			},
		}),

		updateSocialGroupPostInteraction: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateSocialGroupPostInteraction}/${id}/update`,
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialGroupPosts']));
			},
		}),

		deleteSocialGroupPostInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.deleteSocialGroupPostInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialGroupPosts']));
			},
		}),

		removeSocialGroupPostInteraction: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.removeSocialGroupPostInteraction}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialGroupPosts']));
			},
		}),

		toggleMuteSocialGroupPost: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.toggleMuteSocialGroupPost}/${id}/toggle-mute`,
					method: 'POST',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialGroupPosts']));
			},
		}),

		upadteFlagsForSocialGroupPost: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.upadteFlagsForSocialGroupPost}/${id}/update-flags`,
					method: 'PATCH',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['socialGroupPosts']));
			},
		}),

		updateAdRequestForAdmin: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.updateAdsRequestAcceotanceStatusForAdmin}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),

		updatePersonalActivationStatus: build.mutation({
			query: ({ activation, status }) => ({
				url: `${endpoints.updatePersonalActivationStatus}/${activation}`,
				method: 'POST',
				body: {
					status,
				},
			}),
		}),

		updateEntityActivationStatus: build.mutation({
			query: ({ activation, status }) => ({
				url: `${endpoints.updateEntityActivationStatus}/${activation}`,
				method: 'POST',
				body: {
					status,
				},
			}),
		}),

		updateCreditsApproveRequests: build.mutation<any, any>({
			query: ({ ...body }) => {
				return {
					url: `${endpoints.updateAllCreditRequests}`,
					method: 'PUT',
					body,
				};
			},
		}),
		deleteNoteCourse: build.mutation<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.storeNote}/${id}`,
					method: 'DELETE',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Notes']));
			},
		}),

		// updatePlanFeatures: build.mutation<any, any>({
		// 	query: ({ token, plan_id, features }) => ({
		// 		url: endpoints.updatePlans,
		// 		method: 'PUT',
		// 		params: { token },
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 			Accept: 'application/json',
		// 		},
		// 		body: {
		// 			plan_id,
		// 			features,
		// 		},
		// 	}),
		// }),

		updatePlanFeatures: build.mutation<any, any>({
			query: (body) => ({
				url: endpoints.updatePlans,
				method: 'PUT',

				body,
			}),
		}),

		updateBadges: build.mutation<any, any>({
			query: ({ token, badges }) => ({
				url: endpoints.adminBadges + '/update',
				method: 'POST',
				params: { token },
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: { badges },
			}),
		}),

		editNote: build.mutation<any, any>({
			query: (body) => ({
				url: `${endpoints.storeNote}/${body.id}`,
				method: 'PUT',
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Notes']));
			},
		}),

		//gatway
		addPaypalAccount: build.mutation<any, any>({
			query: (body) => ({
				url: `${endpoints.addPaypalAccount}`,
				method: 'POST',
				body, // sending the fields in the body
			}),
		}),
		addStripeAccount: build.mutation<StripePaymentResponse, any>({
			query: (body) => ({
				url: `${endpoints.addStripeAccount}`,
				method: 'POST',
				body, // sending the fields in the body
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Wallet']));
			},
		}),

		LockReport: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.ToggleReportLock}/${id}/toggle_is_locked`,
					method: 'POST',
				};
			},
		}),

		DeleteAdminReport: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.DeleteAdminReport}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		// updateOrderStatus
		updateOrderStatus: build.mutation<any, { id: number; body: any }>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.updateOrderStatus}/${id}/item_status`,
					method: 'PUT', // Assuming a POST request is intended for updates
					body,
				};
			},
		}),
		updateAdSpot: build.mutation<any, { id: number; body: any }>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.adspot}/${id}/update`,
					method: 'POST',
					body,
				};
			},
		}),

		MoveToWishList: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.MoveToWishList,
					method: 'POST',
					body: body,
				};
			},
		}),

		toggleLockAsset: build.mutation<any, any>({
			query: ({ id, name }) => {
				return {
					url: endpoints.toggleLockAsset,
					method: 'POST',
					body: {
						asset_id: id,
						asset_name: name,
					},
				};
			},
		}),
		addAdminCategory: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.adminCategory,
					method: 'POST',
					body: body,
				};
			},
		}),

		addAdminSubCategory: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.adminSubCategory,
					method: 'POST',
					body: body,
				};
			},
		}),

		addAdminLevel: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.adminLevel,
					method: 'POST',
					body: body,
				};
			},
		}),

		updateAdminCategory: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.adminCategory}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),

		updateAdminSubCategory: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.adminSubCategory}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),

		updateAdminLevel: build.mutation<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.adminLevel}/${id}`,
					method: 'PUT',
					body: body,
				};
			},
		}),

		removeAdminCategory: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.adminCategory}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		removeAdminSubCategory: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.adminSubCategory}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		removeAdminLevel: build.mutation<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.adminLevel}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		removeBulkAdminCategory: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.adminCategory}/bulk`,
					method: 'POST',
					body: body,
				};
			},
		}),

		removeBulkAdminSubCategory: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.adminSubCategory}/bulk`,
					method: 'POST',
					body: body,
				};
			},
		}),

		removeBulkAdminLevel: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.adminLevel}/bulk`,
					method: 'POST',
					body: body,
				};
			},
		}),

		redeemCredits: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.redeemCredits,
					method: 'POST',
					body: body,
				};
			},
		}),
		citiesIds: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.citiesIds}`,
					method: 'POST',
					body,
				};
			},
		}),

		//Feelance Application and Contract Process:

		createFreelanceProjectInvitation: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.createFreelanceProjectInvitation,
					method: 'POST',
					body,
				};
			},
		}),

		applyServiceContractRequest: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.applyServiceContractRequest,
					method: 'POST',
					body,
				};
			},
		}),

		applySponsorshipContractRequest: build.mutation<any, any>({
			query: (body) => {
				return {
					url: endpoints.applySponsorshipContractRequest,
					method: 'POST',
					body,
				};
			},

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		applyProjectInviation: build.mutation<any, any>({
			query: ({ invitation_id, body }) => {
				return {
					url: `${endpoints.applyProjectInviation}/${invitation_id}`,
					method: 'PUT',
					body,
				};
			},
		}),

		//JOB APPlICATION
		applyJobInviation: build.mutation<any, any>({
			query: ({ invitation_id, type, body }) => {
				return {
					url: `${endpoints.baseJobInvitationById}/${type}/${invitation_id}`,
					method: 'POST',
					body,
				};
			},
		}),
	}),
});

export const {
	useCitiesIdsMutation,
	useUpdateAdSpotMutation,
	useUpdateAdRequestForAdminMutation,
	useUpdateCreditsApproveRequestsMutation,
	useRemoveWishlistItemsMutation,
	useMoveToWishListMutation,
	useUpdateSettingsMutation,
	useUpdateSingleSettingMutation,
	// useGetDataByAssentPagenationMutation,
	useLockReportMutation,
	useDeleteAdminReportMutation,
	useExamSubmissionMutation,
	useQuizSubmissionMutation,
	useDeleteNoteCourseMutation,
	useEditNoteMutation,
	// removeVNationGalleryBulkUpload
	useRemoveVNationGalleryBulkUploadMutation,
	useStoreNoteMutation,
	useCreateSharedMutation,
	useVNationDeleteGalleryByIdMutation,
	useLogoutMutation,
	useRegisterUsingPhoneMutation,
	useRegisterUsingEmailMutation,
	useSendPhoneVerificationMutation,
	useSendEmailVerificationMutation,
	useLoginUsingPhoneMutation,
	useLoginUsingEmailMutation,
	useVerifyPhoneCodeMutation,
	useVerifyEmailCodeMutation,
	useCreateBlogMutation,
	useWalletFundMutation,
	useWalletWithdrawMutation,
	useChangeWalletsDefaultMutation,
	useDeleteWalletByIdMutation,

	useUpdatePersonalProfileMutation,
	useUpdatePersonalProfileLanguageMutation,
	useDeletePersonalProfileLanguageMutation,
	useUpdatePersonalProfileSocialMediaMutation,
	useDeletePersonalProfileSocialMediaMutation,
	useUpdatePersonalProfileStoreAttachmentsMutation,
	useStoreNewEntityProfileMutation,
	useCreateEventPostInteractionsMutation,
	useSocialPageMutation,
	useCreateJobMutation,
	useDeleteNotificationsMutation,
	useNotificationMuteMutation,
	useNotificationUnmuteMutation,
	useRemoveHistoryMutation,
	useLoginRelatedAccountMutation,
	useRemoveNotificationMutation,
	useSendFCMTokenMutation,
	useDeleteHistoryMutation,
	useSocialGroupMutation,
	useCreateFolderMutation,
	useDeleteFolderMutation,
	useMoveFolderToTrashMutation,
	useRenameFileMutation,
	useUpdateFolderMutation,
	useMoveFolderMutation,
	useCloneFolderMutation,
	useUpdateNoteMutation,
	useNewNoteMutation,
	useDeleteNoteMutation,
	useUploadFoldersFilesMutation,
	useDeleteNewsMutation,
	useDeleteAdRequestMutation,
	useUpdateAdStatusMutation,
	useVerifyUserIdMutation,
	useMyStorageFilePutInTrashMutation,
	useWelcomeFlagMutation,
	useMyStorageFileMoveToFolderMutation,
	useCreateServiceMutation,
	useUpdateServiceMutation,
	useDeleteBulkNewsMutation,
	useDeleteBulkNotesMutation,
	useProjectMutation,
	useRoomsUpdateFavoriteHiddenMuteMutation,
	useUpdateOnlineStatusMutation,
	useCreateMessageMutation,
	useStoreConversationPrivateNoteMutation,
	useUpdateConversationClearOrDeleteMutation,
	useUpdateConversationFavoriteOrHiddenOrMuteMutation,
	useUpdateUserBlockMutation,
	useCreateAdRequestMutation,
	useCreateScheduleMeetingMutation,
	useDestroyMessageMutation,
	useStoreRoomPrivateNoteMutation,
	useUpdateMessageMutation,
	useCreatePlannerScheduleMeetingMutation,
	useCreatePlannerScheduleToDoMutation,
	useUpdatePlannerScheduleMeetingMutation,
	useUpdatePlannerScheduleToDoMutation,
	useUpdateMyBlogPostMutation,
	useValidateCouponMutation,
	useUpdateMyBookMutation,
	useDeletePlanMutation,
	useStartAudioCallMutation,
	useStartRecordingMutation,
	useAcceptCallMutation,
	useDeclineCallMutation,
	useMuteCallMutation,
	useParticipantJoinedMutation,
	useParticipantLeftMutation,
	useStartVideoCallMutation,
	useStopRecordingMutation,
	useUnMuteCallMutation,
	useTurnOnCameraMutation,
	useTurnOffCameraMutation,
	useCreateEventSpeakersMutation,
	useRoomsUpdateClearDeleteMutation,
	useCreateRoomMutation,
	useCreateRoomMessageMutation,
	useDestroyRoomMessageMutation,
	useUpdateRoomMessageMutation,
	useInitiateAnswerSignalMutation,
	useInitiateOfferSignalMutation,
	useCallEndMutation,
	useUpdateRoomMutation,
	useAddRoomsParticipantsMutation,
	useRemoveRoomsParticipantsMutation,
	useUpdateRoomsParticipantsRoleMutation,
	useVideoChannelsMutation,
	useRoomAcceptMutation,
	useRoomAcceptRecordingMutation,
	useRoomAcceptSignalMutation,
	useRoomDeclineCallMutation,
	useRoomMuteMutation,
	useDeleterGalleryMutation,
	useRoomOfferSignalMutation,
	useRoomParticipantJoinedMutation,
	useRoomParticipantLeftMutation,
	useRoomRequestRecordingMutation,
	useRoomStartAudioCallMutation,
	useRoomStartRecordingMutation,
	useRoomStartVideoCallMutation,
	useRoomStopRecordingMutation,
	useRoomTurnOffCameraMutation,
	useRoomTurnOnCameraMutation,
	useRoomUnMuteMutation,
	useCreateEndorsmentMutation,
	useCreateRequestEndorsmentMutation,
	useUpdateEndorsmentByIdMutation,
	useDeleteEndorsmentByIdMutation,
	useCreateEventMutation,
	useBirthdayPostReqMutation,
	useAcceptOrRejectBirthdayPostReqMutation,
	useHideUsersBirthdayOrAnniversaryMutation,
	useHideUserSuggestionsMutation,
	useDisconnectUserMutation,
	useSendConnectionRequestMutation,
	useAcceptOrRejectConnectionRequestMutation,
	useDeleteConnectionRequestMutation,
	useCreateRoomScheduleMeetingMutation,
	useCreateOrUpdateRoomLabelMutation,
	useCreateOrUpdateChatLabelMutation,
	useGetSavedUsersByModuleAssetNameMutation,
	useDisconnectBulkUsersMutation,
	useConnectBulkUsersMutation,
	useBlockBulkUsersMutation,
	useMessageBulkUsersMutation,
	useCreategalleryMutation,
	useChangeConnectionReqStatusMutation,
	useAcceptDeclineMeetingsMutation,
	useDecideMeetingMutation,
	useStoreNewEntityUserMutation,
	useDeleteReferralsMutation,
	useBlogBulkInteractionMutation,
	useBulkInteractionMutation,
	useBulkProjectApplicationsActionMutation,
	useUnSaveBulkUserMutation,
	useMakeBulkEndorsementActionMutation,
	useExitRoomMutation,
	useReportMutation,
	useBulkBlogPostsMutation,
	useUpdateReportMutation,
	useCreateJobApplicationMutation,
	useChangeJobApplicationStatusMutation,
	useBulkWithdrawJobApplicationMutation,
	useDeleteProfileInteractionsMutation,
	useUpdateProfileInteractionMutation,
	useBulkJobpostingMutation,
	useApplyJobMutation,
	useBookConsultationMutation,
	useUpdateJobStatusMutation,
	useCreateProfileInteractionMutation,
	useUnBlockUserMutation,
	useCreateEndorsmentsMutation,
	useDeleteJobApplicationMutation,
	useBlockUserMutation,
	useJobPostingInteractionMutation,
	useBulkBlogActionMutation,
	useDeleteBlogPostMutation,
	useDeleteBookMutation,
	useUpdateScheduleMeetingMutation,
	useUpdateRoomScheduleMeetingMutation,
	useUnsaveJobMutation,
	useCreateBlogPostInteractionsMutation,
	useUpdateBlogPostInteractionsMutation,
	useDeleteBlogPostInteractionsMutation,
	useBookInterationMutation,
	useCreateBookInteractionsMutation,
	useUpdateBookInteractionsMutation,
	useCreateConsultationInteractionsMutation,
	useUpdateConsultationInteractionsMutation,
	useUpdateJobPostingMutation,
	useUnsaveUserByModuleAndAssetNameMutation,
	useBulkJobCandidateStatusUpdateMutation,
	useUpdateEventPostInteractionsMutation,
	useSendEntityEmailVerificationMutation,
	useSendEntityPhoneVerificationMutation,
	useVerifyEntityPhoneCodeMutation,
	useVerifyEntityEmailCodeMutation,
	useBulkJobInviteMutation,
	useEmojisStoreMutation,
	useBulkHideBirthdayAnniversaryMutation,
	useHideBirthdayAnniversaryMutation,
	useUpdateJobPostingInteractionMutation,
	useDeleteJobPostingInteractionMutation,
	useBulkHideSuggestedUsersMutation,
	useUnsaveBlogPostMutation,
	useUnsaveBookMutation,
	useDeleteShippingAddressesMutation,
	useDeleteLanguageMutation,
	useDeleteWorkExperienceMutation,
	useDeleteAchievementMutation,
	useDeleteEducationMutation,
	useDeleteSkillSetMutation,
	useDeleteSkillMutation,
	useUpdateBusinessProfileMutation,
	useDeletePersonalSocialLinksMutation,
	useDeleteBusinessSocialLinkMutation,
	useDeleteBusinessSocialBranchLinkMutation,
	useBulkJobPostingUpdateMutation,
	useSponsorShipStoreMutation,
	useCreateDeliverySubmissionReqMutation,
	useCancelContractReqMutation,
	useUpdateSponsorShipByIdMutation,
	useCreateBookMutation,
	useUnSaveEventMutation,
	useUpdateBookByIdMutation,
	useCreateSponsorshipInteractionMutation,
	useUpdateSponsorshipInteractionMutation,
	useDeleteSponsorshipInteractionMutation,
	useUnsaveSponsorshipMutation,
	useUpdateChannelsMutation,
	useUnsaveCourseMutation,
	useCourseInteractionMutation,
	useUnitLessonInteractionMutation,
	useUpdateCourseInteractionMutation,
	useUpdateUnitLessonInteractionMutation,
	useDeleteCourseInteractionMutation,
	useDeleteUnitLessonInteractionMutation,
	useDeleteEventByIdMutation,
	useRemoveSavedCastInteractionMutation,
	useConsultationInteractionMutation,
	useServiceInteractionMutation,
	useProjectInteractionMutation,
	useProjectUnsaveMutation,
	useCreateCastInteractionMutation,
	useUpdateCastInteractionMutation,
	useUpdateCastEpisodeInteractionMutation,
	useRemoveEpisodeByIdMutation,
	useVideoPlaylistMutation,
	useDeleteCastInteractionMutation,
	usePodcastStoreMutation,
	useDeleteCouponMutation,
	useUpdateMyEventsMutation,
	useDeleteBookAttachmentMutation,
	useUpdatePodcastsMutation,
	useWishlistMutation,
	useSponsorshipBulkActionMutation,
	useUnsaveStoreBulkActionMutation,
	useCollegeBulkActionMutation,
	useServiceBulkActionMutation,
	useWishlistRemoveMutation,
	useDeleteBookInteractionMutation,
	useDeleteConsultationInteractionMutation,
	useBulkRemoveBookFromDownloadsMutation,
	useBulkDownloadBooksMutation,
	useAddCartMutation,
	useRemoveCartMutation,
	useBulkBookActionMutation,
	useBulkConsultationActionMutation,
	useCreateEventInteractionMutation,
	useUpdateEventInteractionMutation,
	useDeleteEventInteractionMutation,
	useDeleteCastEpisodeSavedMutation,
	useBulkSaveOrUnsaveOrLikeEventsMutation,
	useBulkSaveOrUnsaveOrLikeChannelsMutation,
	useCreateConsultationMutation,
	useAddToPlaylistVTubeMutation,
	useUpdateConsultationMutation,
	useBulkRemoveDownloadTicketMutation,
	useBulkDownloadTicketsMutation,
	useBulkEpisodesActionMutation,
	useBulkPodcastActionMutation,
	useBulkDeletePodcastsMutation,
	useBulkSubscribeOrUnsubscribePodcastsMutation,
	useSponsorshipContactTierMutation,
	useCreateEpisodeInteractionMutation,
	useUpdateEpisodeInteractionMutation,
	useDeleteEpisodeInteractionMutation,
	useCreateVideoInteractionMutation,
	useDeleteVidoeInteractionMutation,
	useUpdateVideoInteractionMutation,
	useBulkSaveOrUnsaveOrLikeVideosMutation,
	useBulkSaveOrUnsaveOrLikeShortsMutation,
	useEventBulkDeleteMutation,
	useEventBulkPublishMutation,
	useEventBulkArchiveMutation,
	useEventBulkDraftMutation,
	useProjectBulkActionMutation,
	useSaveProjectMutation,
	useRemoveProjectMutation,
	useCreateProjectInteractionMutation,
	useUpdateProjectInteractionMutation,
	useDeleteProjectInteractionMutation,
	useCreateFreelanceProjectInvitationMutation,

	useServicePostingInteractionMutation,
	useDeleteServicePostingInteractionMutation,
	useUpdateServicePostingInteractionMutation,

	useCreateChannelInteractionMutation,
	useUpdateChannelInteractionMutation,
	useDeleteChannelCommentMutation,
	usePodcastEpisodeStoreMutation,
	useUnsaveConsultationMutation,
	useAddToWatchLaterMutation,
	useCreateStoreMutation,
	useDeleteStoreByIdMutation,
	useUpdateStoreByIdMutation,
	useSavedRemoveStoreInteractionMutation,
	useDeleteStoreInteractionMutation,
	useCreateStoreInteractionMutation,
	useUpdateStoreInteractionMutation,
	useCreateServiceInteractionMutation,
	useCreateVNationPostGalleryMutation,
	useUpdateVNationPostGalleryMutation,
	useUpdateServiceInteractionMutation,
	useDeleteServiceInteractionMutation,
	useUnsaveServiceMutation,
	useBulkFreelanceServiceActionMutation,
	useDestroyFreelancingServiceMutation,
	useDestroyFreelancingProjectMutation,
	useProjectUpdateMutation,
	useGalleryAttechmentRemoveManyByIdsMutation,
	useGalleryAttachmentUpdateMutation,
	useGetApplicantsByProjectIdMutation,
	useCreateWebinarInteractionMutation,
	useUpdateWebinarInteractionMutation,
	useDeleteWebinarInteractionMutation,
	useUnsaveWebinarMutation,
	useWebinarStoreMutation,
	useWebinarUpdateMutation,
	useAddToQueueVideosMutation,
	useNotifyAboutChannelMutation,
	useDontNotifyAboutChannelMutation,
	useDeleteCouponWebinarMutation,
	useApplyProjectMutation,
	useUpdateProjectStatusMutation,
	useStoreCourseMutation,
	useUpdateCourseMutation,
	useStoreCourseUnitMutation,
	useDeleteCourseUnitMutation,
	useUpdateCourseUnitMutation,
	useDeleteCourseUnitLessonMutation,
	useStoreQuizUnitMutation,
	useUpdateQuizUnitMutation,
	useDeleteQuizUnitMutation,
	useDeleteWebinarByIdMutation,
	useBulkWebinarActionMutation,
	useDeletePlaylistMutation,
	useUpdateEntityUserMutation,
	useUpdatePlaylistMutation,
	useStoreExamMutation,
	useUpdateExamMutation,
	useDeleteExamMutation,
	useChangeProjectApplicationStatusMutation,
	useDeleteProjectApplicationMutation,
	useBulkProjectApplicantStatusUpdateMutation,
	useStoreAnnouncementMutation,
	useUpdateAnnouncementMutation,
	useDeleteAnnouncementMutation,
	useCloneFileMutation,
	useCreateSocialTimelinePostInteractionMutation,
	useUpdateSocialTimelinePostInteractionMutation,
	useDeleteSocialTimelinePostInteracionMutation,
	useRemoveSocialTimelinePostInteractionMutation,
	useMuteSocialPagePostMutation,
	useMuteSocialGroupPostMutation,
	useUpdateGuideCreditsMutation,
	useMuteSocialTimelinePostMutation,
	useUnmuteSocialTimelinePostMutation,
	useToggleMuteSocialTimelinePostMutation,
	useToggleMuteSocialGroupPostMutation,
	useToggleMuteSocialPagePostMutation,
	useUpdateFlagsForSocialTimelinePostMutation,
	useUpdateFlagsForSocialPagePostMutation,
	useUpadteFlagsForSocialGroupPostMutation,

	useBulkSocialPageInteractionMutation,
	useCreateSocialPageInteractionMutation,
	useUpdateSocialPageInteractionMutation,
	useDeleteSocialPageInteractionMutation,
	useBulkActionSocialTimelinePostMutation,
	useBulkAcrossSocialPostMutation,
	useBulkActionSocialPagePostMutation,
	useAddWishlistMutation,
	useUpdateWishlistMutation,
	useRemoveWishlistMutation,
	useRemoveBulkWishlistItemMutation,
	useChangeDefaultWishlistMutation,
	useMoveBulkWishlistMutation,
	useBulkSocialGroupInteractionMutation,
	useCreateSocialGroupInteractionMutation,
	useUpdateSocialGroupInteractionMutation,
	useDeleteSocialGroupInteractionMutation,
	useRemoveFromSavedUnsaveGroupMutation,
	useJoinGroupVNationMutation,
	useLeaveGroupVNationMutation,
	useFollowPageVNationMutation,
	useUnfollowPageVNationMutation,
	useUnfollowPageVNation2Mutation,
	useDeleteSocialPageMutation,
	useDownloadInvoiceMutation,
	useDeleteSocialGroupMutation,
	useUpdateStoreMutation,
	useStoreStoreMutation,
	useDeleteCourseCouponMutation,
	useContractServiceTierMutation,

	useSavedProductMutation,
	useUnSavedProductMutation,
	useProductBulkInteractionMutation,
	useCreateProductInteractionMutation,
	useBulkProductInteractionMutation,
	useStoreBulkActionsMutation,
	useDeleteProductInteractionMutation,
	useUpdateProductInteractionMutation,
	useStoreCourseInstructorMutation,
	useAddToCartMutation,
	useRemoveFromCartMutation,
	useUpdateShippingAddressMutation,
	useUpdateItemShippingOptionMutation,
	useUpdateItemQuantityMutation,
	useApplyCouponMutation,
	useRemoveCouponMutation,
	useCheckoutMutation,
	useGetAllProductPostsMutation,
	useDeleteSponsorshipMutation,
	useUpdateSocialPageMutation,
	useUpdateSocialGroupMutation,
	useMoveFileInTrashMutation,
	useMoveBulkFilesInTrashMutation,
	useRestoreFromTrashMutation,
	useMoveBulkFoldersInTrashMutation,
	useMoveBulkFilesInFolderMutation,
	useDeleteFileMutation,
	useRestoreFileMutation,
	useBulkDownloadProductsMutation,
	useUnSaveEpisodeMutation,
	useBulkSocialPageActionMutation,
	useRemoveFromSavedUnsaveSocialPageMutation,
	useDeleteInstructorsByIdMutation,
	useUnsaveConsultationInteractionMutation,
	useValidateCouponAdsMutation,
	useDeleteSocialPagePostMutation,
	useDeleteSocialGroupPostMutation,
	useDeleteSocialTimelinePostMutation,

	useCreateSocialGroupPostInteractionMutation,
	useDeleteSocialGroupPostInteractionMutation,
	useUpdateSocialGroupPostInteractionMutation,
	useRemoveSocialGroupPostInteractionMutation,
	useCreateSocialPagePostInteractionMutation,
	useDeleteSocialPagePostInteractionMutation,
	useUpdateSocialPagePostInteractionMutation,
	useUpdatePlanFeaturesMutation,
	useSubsribeUserPlanMutation,
	useRemoveSocialPagePostInteractionMutation,

	useAddPaypalAccountMutation,
	useAddStripeAccountMutation,

	useUpdateBadgesMutation,
	useBulkdownloadInvoicesMutation,
	useUpdateOrderStatusMutation,
	useToggleLockAssetMutation,

	// ADMIN PANEL CATEGORIES
	useAddAdminCategoryMutation,
	useAddAdminSubCategoryMutation,
	useAddAdminLevelMutation,
	useUpdateAdminCategoryMutation,
	useUpdateAdminSubCategoryMutation,
	useUpdateAdminLevelMutation,
	useRemoveAdminCategoryMutation,
	useRemoveAdminSubCategoryMutation,
	useRemoveAdminLevelMutation,
	useRemoveBulkAdminCategoryMutation,
	useRemoveBulkAdminSubCategoryMutation,
	useRemoveBulkAdminLevelMutation,
	useSubscribeToChannelMutation,
	useSubscribeChannelMutation,
	useUnsubscribeFromChannelMutation,
	useRedeemCreditsMutation,
	useRemoveTrackerHistoryMutation,
	useUpdatePersonalActivationStatusMutation,
	useUpdateEntityActivationStatusMutation,
	useUpdateSocialGroupPostMutation,
	useUpdateSocialPagePostMutation,
	useUpdateSocialTimelinePostMutation,
	useApplyServiceContractRequestMutation,
	useApplySponsorshipContractRequestMutation,
	useApplyProjectInviationMutation,
	useApplyJobInviationMutation,
	usePublishSocialTimelinePostMutation,
	useAddVoteToPollAnswerMutation,
} = mutationAPI;
